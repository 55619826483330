*{

  box-sizing: border-box;
}


*{
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {

}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

#root{

  overflow: hidden;
  height: 100vh;
  width: 100vw;

}

body{
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: #333;
  width: 100vw;
  overflow: hidden;
}

@keyframes animateloader {
  0% {
    width: 30%;
    left: -30%;
  }
  70% {
    left: 100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}